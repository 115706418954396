export const OBTENER_SUCURSALES_USUARIO_EXITO = "OBTENER_SUCURSALES_USUARIO_EXITO"
export const OBTENER_SUCURSALES_USUARIO_FAIL = "OBTENER_SUCURSALES_USUARIO_FAIL"
export const FILTRO_SELECCIONAR_SUCURSAL_USUARIO = "FILTRO_SELECCIONAR_SUCURSAL_USUARIO"
export const REINICIAR_SUCURSALES_USUARIO = "REINICIAR_SUCURSALES_USUARIO"
export const OBTENER_SUCURSALES_USUARIO = "OBTENER_SUCURSALES_USUARIO"
export const SELECCIONAR_SUCURSAL = "SELECCIONAR_SUCURSAL"
export const SELECCIONAR_UNA_SUCURSAL_DESCARGAR = "SELECCIONAR_UNA_SUCURSAL_DESCARGAR"
export const SELECCIONAR_UNA_ZONA_DESCARGAR = "SELECCIONAR_UNA_ZONA_DESCARGAR"
export const SELECCIONAR_UN_GRUPO_DESCARGAR = "SELECCIONAR_UN_GRUPO_DESCARGAR"
export const SELECCIONAR_UN_GSUID = "SELECCIONAR_UN_GSUID"
export const SELECCIONAR_CASS = "SELECCIONAR_CASS"
export const SELECCIONAR_ZONAS = "SELECCIONAR_ZONAS"
export const SELECCIONAR_GRUPOS = "SELECCIONAR_GRUPOS"
export const SELECCIONAR_SUCURSALES = "SELECCIONAR_SUCURSALES"
export const APLICANDO_FILTROS_CORRESPONDIENTES = "APLICANDO_FILTROS_CORRESPONDIENTES"
export const CAMBIAR_APLICANDO_FILTRO_ACUMULADO = "CAMBIAR_APLICANDO_FILTRO_ACUMULADO"
export const FILTRO_DISTRIBUIDORA_ELEMENTOS_ENVIADOS = "FILTRO_DISTRIBUIDORA_ELEMENTOS_ENVIADOS"
export const SELECCIONAR_TODO_FILTRO_DISTRIBUIDORA_ELEMENTOS_ENVIADOS = "SELECCIONAR_TODO_FILTRO_DISTRIBUIDORA_ELEMENTOS_ENVIADOS"