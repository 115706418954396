import React, { useState } from 'react'
import { Spin } from 'antd'
import '../../../Estilos/Componentes/Status/TablaStatus.css'
import ModalStatus from './ModalStatus';
import DataAnalytics from '../../../Assets/Img/Status/DataAnalytics.png'
import Soporte from '../../../Assets/Img/Status/SoporteGrow.png'
import TradeMarketing from '../../../Assets/Img/Status/TradeMarketing.png'
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";

const TablaStatus = (props) => {
   
    const data_control_archivos = props.data_control_archivos
    
    const [modalAbierto, setModalAbierto] = useState(false)

    const {
        cargandoTablaStatus
    } = useSelector(({status}) => status);

    return (
        <Spin
            size='large'
            spinning={cargandoTablaStatus}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
            <div className='Contenedor-Tabla-Status'>
                <table className='Tabla-Status'>
                    <thead>
                        <tr>
                            <th style={{width: '152px'}}>Área</th>
                            <th style={{width: '27%', textAlign: 'left', paddingLeft: '40px'}}>Base de Datos</th>
                            <th>Responsable</th>
                            <th>Usuario</th>
                            <th>DeadLine</th>
                            <th>Fecha de Carga</th>
                            <th>Días de Retraso</th>
                            <th style={{width: '10%'}}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data_control_archivos.map((archivos, pos) => {
                                if (archivos.badnombre == 'Sell In Objetivo' && archivos.areid == '1') {
                                    return(
                                        <tr>
                                            <td rowSpan={9} style={{background: 'white'}}>
                                                <img src={TradeMarketing} style={{width:'35px'}}/>
                                                <div>{archivos.arenombre}</div>
                                            </td>
                                            <td style={{textAlign: 'left', paddingLeft: '30px', borderLeft: '1px solid #E5E5E5'}} >
                                                <a href={archivos.carurl} style={{color: '#1E1E1E'}}>{archivos.badnombre}</a> 
                                            </td>
                                            <td>{archivos.pernombrecompletoresponsable}</td>
                                            <td>{archivos.pernombrecompletosubida}</td>
                                            <td>{archivos.coafechacaducidad}</td>
                                            <td>{archivos.fechaCar}</td>
                                            <td className='Columna-Dias-Retraso'>
                                                {
                                                    archivos.fechaCar ? (
                                                        <div className='Columna-Dias-Retraso'>
                                                            <div style={{marginRight: '6px'}}>{archivos.diasretraso} días</div>
                                                            <div className={archivos.diasretraso == 0 ? 'Circulo-Dias-Verde' : 'Circulo-Dias-Rojo'}></div>
                                                        </div>
                                                    ) : ""
                                                }
                                            </td>
                                            <td className={archivos.estnombre == 'Cargado' ? 'Verde' : 'Rojo'}>{archivos.estnombre}</td>
                                        </tr>
                                    )
                                }else if (archivos.badnombre == 'Mecánica Promocional Sur' && archivos.areid == '1') {
                                    return (
                                        <tr>
                                            <td style={{textAlign: 'left', paddingLeft: '30px' , paddingTop: '8px', paddingBottom: '8px', borderLeft: '1px solid #E5E5E5'}}>
                                                <a href={archivos.carurl} style={{marginBottom: '6px', color: '#1E1E1E'}}>{archivos.badnombre}</a>
                                                <div className='Txt-Detalle' 
                                                    onClick={() => setModalAbierto(true)}
                                                >
                                                    Click Detalle
                                                </div>
                                            </td>
                                            <td>{archivos.pernombrecompletoresponsable}</td>
                                            <td>{archivos.pernombrecompletosubida}</td>
                                            <td>{archivos.coafechacaducidad}</td>
                                            <td>{archivos.fechaCar}</td>
                                            <td className='Columna-Dias-Retraso' style={{paddingTop: '16px', paddingBottom: '8px'}}>
                                                {
                                                    archivos.fechaCar ? (
                                                        <div className='Columna-Dias-Retraso'>
                                                            <div style={{marginRight: '6px'}}>{archivos.diasretraso} días</div>
                                                            <div className={archivos.diasretraso == 0 ? 'Circulo-Dias-Verde' : 'Circulo-Dias-Rojo'}></div>
                                                        </div>
                                                    ): ""
                                                }
                                            </td>
                                            <td className={archivos.estnombre == 'Cargado' ? 'Verde' : 'Rojo'} >{archivos.estnombre}</td>
                                        </tr>   
                                    )
                                }else if(archivos.areid == '1'){
                                    return (
                                        <tr>
                                            <td style={{textAlign: 'left', paddingLeft: '30px', borderLeft: '1px solid #E5E5E5'}}>
                                                <a href={archivos.carurl} style={{color: '#1E1E1E'}}>{archivos.badnombre}</a> 
                                            </td>
                                            <td>{archivos.pernombrecompletoresponsable}</td>
                                            <td>{archivos.pernombrecompletosubida}</td>
                                            <td>{archivos.coafechacaducidad}</td>
                                            <td>{archivos.fechaCar}</td>
                                            <td className='Columna-Dias-Retraso'>
                                                {
                                                    archivos.fechaCar ? (
                                                        <div className='Columna-Dias-Retraso'>
                                                            <div style={{marginRight: '6px'}}>{archivos.diasretraso} días</div>
                                                            <div className={archivos.diasretraso == 0 ? 'Circulo-Dias-Verde' : 'Circulo-Dias-Rojo'}></div>
                                                        </div>
                                                    ) : ""
                                                }
                                            </td>
                                            <td className={archivos.estnombre == 'Cargado' ? 'Verde' : 'Rojo'} >{archivos.estnombre}</td>
                                        </tr>   
                                    )
                                }
                            })
                        }
                        {
                            data_control_archivos.map((archivos, pos) => {
                                if (archivos.badnombre == 'Sell In Real' && archivos.areid == '2') {
                                    return(
                                        <tr style={{background: '#FFFFFF'}}>
                                            <td rowSpan={4} style={{background: 'white'}}>
                                                <img src={DataAnalytics} style={{width:'35px'}}/>
                                                <div>{archivos.arenombre}</div>
                                            </td>
                                            <td style={{textAlign: 'left', paddingLeft: '30px', borderLeft: '1px solid #E5E5E5'}}>
                                                <a href={archivos.carurl} style={{color: '#1E1E1E'}}>{archivos.badnombre}</a> 
                                            </td>
                                            <td>{archivos.pernombrecompletoresponsable}</td>
                                            <td>{archivos.pernombrecompletosubida}</td>
                                            <td>{archivos.coafechacaducidad}</td>
                                            <td>{archivos.fechaCar}</td>
                                            <td className='Columna-Dias-Retraso'>
                                                {
                                                    archivos.fechaCar ? (
                                                        <div className='Columna-Dias-Retraso'>
                                                            <div style={{marginRight: '6px'}}>{archivos.diasretraso} días</div>
                                                            <div className={archivos.diasretraso == 0 ? 'Circulo-Dias-Verde' : 'Circulo-Dias-Rojo'}></div>
                                                        </div>
                                                    ) : ""
                                                }
                                            </td>
                                            <td className={archivos.estnombre == 'Cargado' ? 'Verde' : 'Rojo'} >{archivos.estnombre}</td>
                                        </tr>
                                    )
                                }else if(archivos.areid == '2'){
                                    return (
                                        <tr style={{background: '#FFFFFF'}}>
                                            <td style={{textAlign: 'left', paddingLeft: '30px', borderLeft: '1px solid #E5E5E5'}}>
                                                <a href={archivos.carurl} style={{color: '#1E1E1E'}}>{archivos.badnombre}</a> 
                                            </td>
                                            <td>{archivos.pernombrecompletoresponsable}</td>
                                            <td>{archivos.pernombrecompletosubida}</td>
                                            <td>{archivos.coafechacaducidad}</td>
                                            <td>{archivos.fechaCar}</td>
                                            <td className='Columna-Dias-Retraso'>
                                                {
                                                    archivos.fechaCar ? (
                                                        <div className='Columna-Dias-Retraso'>
                                                            <div style={{marginRight: '6px'}}>{archivos.diasretraso} días</div>
                                                            <div className={archivos.diasretraso == 0 ? 'Circulo-Dias-Verde' : 'Circulo-Dias-Rojo'}></div>
                                                        </div>
                                                    ):""
                                                }   
                                            </td>
                                            <td className={archivos.estnombre == 'Cargado' ? 'Verde' : 'Rojo'} >{archivos.estnombre}</td>
                                        </tr>   
                                    )
                                }
                            })
                        }  
                        {
                            data_control_archivos.map((archivos, pos) => {
                                if (archivos.badnombre == 'Sell Out Real DT' && archivos.areid == '3') {
                                    return(
                                        <tr>
                                            <td rowSpan={4} style={{background: 'white'}}>
                                                <img src={Soporte} style={{width:'35px'}}/>
                                                <div>{archivos.arenombre}</div>
                                            </td>
                                            <td style={{textAlign: 'left', paddingLeft: '30px', borderLeft: '1px solid #E5E5E5'}}>
                                                <a href={archivos.carurl} style={{color: '#1E1E1E'}}>{archivos.badnombre}</a> 
                                            </td>
                                            <td>{archivos.pernombrecompletoresponsable}</td>
                                            <td>{archivos.pernombrecompletosubida}</td>
                                            <td>{archivos.coafechacaducidad}</td>
                                            <td>{archivos.fechaCar}</td>
                                            <td className='Columna-Dias-Retraso'>
                                                {
                                                    archivos.fechaCar ? (
                                                        <div className='Columna-Dias-Retraso'>
                                                            <div style={{marginRight: '6px'}}>{archivos.diasretraso} días</div>
                                                            <div className={archivos.diasretraso == 0 ? 'Circulo-Dias-Verde' : 'Circulo-Dias-Rojo'}></div>
                                                        </div>
                                                    ):""
                                                }
                                            </td>
                                            <td className={archivos.estnombre == 'Cargado' ? 'Verde' : 'Rojo'} >{archivos.estnombre}</td>
                                        </tr>
                                    )
                                }else if(archivos.areid == '3'){
                                    return (
                                        <tr>
                                            <td style={{textAlign: 'left', paddingLeft: '30px', borderLeft: '1px solid #E5E5E5'}}>
                                                <a href={archivos.carurl} style={{color: '#1E1E1E'}}>{archivos.badnombre}</a> 
                                            </td>
                                            <td>{archivos.pernombrecompletoresponsable}</td>
                                            <td>{archivos.pernombrecompletosubida}</td>
                                            <td>{archivos.coafechacaducidad}</td>
                                            <td>{archivos.fechaCar}</td>
                                            <td className='Columna-Dias-Retraso'>
                                                {
                                                    archivos.fechaCar ? (
                                                        <div className='Columna-Dias-Retraso'>
                                                            <div style={{marginRight: '6px'}}>{archivos.diasretraso} días</div>
                                                            <div className={archivos.diasretraso == 0 ? 'Circulo-Dias-Verde' : 'Circulo-Dias-Rojo'}></div>
                                                        </div>
                                                    ):""
                                                }
                                            </td>
                                            <td className={archivos.estnombre == 'Cargado' ? 'Verde' : 'Rojo'} >{archivos.estnombre}</td>
                                        </tr>   
                                    )
                                }
                            })
                        }  
                    </tbody>
                </table>
                <ModalStatus
                    modalAbierto = {modalAbierto}
                    setAbrilModal = {(valor) => {
                        setModalAbierto(valor)
                    }}
                />
            </div>
        </Spin>
    )
}

export default TablaStatus