export const OBTENER_DATOS_IMAGENES = 'OBTENER_DATOS_IMAGENES'
export const OBTENER_DATOS_EDITANDO_SINIMAGENES = 'OBTENER_DATOS_EDITANDO_SINIMAGENES'
export const OBTENER_DATOS_EDITANDO_CONIMAGENES = 'OBTENER_DATOS_EDITANDO_CONIMAGENES'
export const OBTENER_DATOS_FILTRADO_CONIMAGENES = 'OBTENER_DATOS_FILTRADO_CONIMAGENES'
export const OBTENER_DATOS_FILTRADO_SINIMAGENES = 'OBTENER_DATOS_FILTRADO_SINIMAGENES'
export const OBTENER_DATOS_SINIMAGENES = 'OBTENER_DATOS_SINIMAGENES'
export const CARGANDO_TABLA_DATOS_IMAGENES = 'CARGANDO_TABLA_DATOS_IMAGENES'
export const MOSTRAR_CARGANDO_EDITAR_REGISTRO = 'MOSTRAR_CARGANDO_EDITAR_REGISTRO'
export const EDITAR_FECHA_INICIO_FINAL_BANCO_IMAGENES = 'EDITAR_FECHA_INICIO_FINAL_BANCO_IMAGENES'


