export const MODULO_DESCARGA_SELECCIONADO = 'MODULO_DESCARGA_SELECCIONADO'
export const SELECCIONAR_COLUMNA_FILTRO_DESCARGA = 'SELECCIONAR_COLUMNA_FILTRO_DESCARGA'
export const ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR = 'ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR'
export const OBTENER_DATA_DESCARGAR_EXCEL_PROMOCIONES_DESCARGAR = 'OBTENER_DATA_DESCARGAR_EXCEL_PROMOCIONES_DESCARGAR'
export const CARGANDO_BTN_EXCEL_DESCARGAR = 'CARGANDO_BTN_EXCEL_DESCARGAR'

// DESCARGA SELL IN
export const ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_SELLIN = 'ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_SELLIN'
export const SELECCIONAR_COLUMNA_FILTRO_DESCARGA_SELLIN = 'SELECCIONAR_COLUMNA_FILTRO_DESCARGA_SELLIN'
export const OBTENER_DATA_DESCARGAR_EXCEL_SELLIN_DESCARGAR = 'OBTENER_DATA_DESCARGAR_EXCEL_SELLIN_DESCARGAR'

// DESCARGA SELL OUT
export const ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_SELLOUT = 'ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_SELLOUT'
export const SELECCIONAR_COLUMNA_FILTRO_DESCARGA_SELLOUT       = 'SELECCIONAR_COLUMNA_FILTRO_DESCARGA_SELLOUT'
export const OBTENER_DATA_DESCARGAR_EXCEL_SELLOUT_DESCARGAR    = 'OBTENER_DATA_DESCARGAR_EXCEL_SELLOUT_DESCARGAR'

// REPORTE DE PAGOS
export const ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_REPORTEPAGOS = 'ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_REPORTEPAGOS'
export const SELECCIONAR_COLUMNA_FILTRO_DESCARGA_REPORTEPAGOS       = 'SELECCIONAR_COLUMNA_FILTRO_DESCARGA_REPORTEPAGOS'
export const OBTENER_DATA_DESCARGAR_EXCEL_REPORTEPAGOS_DESCARGAR    = 'OBTENER_DATA_DESCARGAR_EXCEL_REPORTEPAGOS_DESCARGAR'

// PROMOCIONES LIQUIDADAS

export const ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_PROMOCIONES_LIQUIDADAS = 'ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_PROMOCIONES_LIQUIDADAS'
export const SELECCIONAR_COLUMNA_FILTRO_DESCARGA_PROMOCIONES_LIQUIDADAS       = 'SELECCIONAR_COLUMNA_FILTRO_DESCARGA_PROMOCIONES_LIQUIDADAS'
export const OBTENER_DATA_DESCARGAR_EXCEL_REPORTEPAGPROMOCIONES_LIQUIDADAS    = 'OBTENER_DATA_DESCARGAR_EXCEL_REPORTEPAGPROMOCIONES_LIQUIDADAS'

// DESCARGAR PDF
export const CARGANDO_BTN_EXCEL_DESCARGAR_PDF    = 'CARGANDO_BTN_EXCEL_DESCARGAR_PDF'

// ENVIAR CORREO
export const CARGANDO_BTN_ENVIAR_CORREO_DESCARGA    = 'CARGANDO_BTN_ENVIAR_CORREO_DESCARGA'
export const NOMBRE_ARCHIVO_ENVIAR_CORREO_DESCARGA    = 'NOMBRE_ARCHIVO_ENVIAR_CORREO_DESCARGA'