export const REINICIAR_PROMOCIONES = 'REINICIAR_PROMOCIONES'
export const OBTENER_PROMOCIONES_EXITO = 'OBTENER_PROMOCIONES_EXITO'
export const OBTENER_PROMOCIONES_FAIL = 'OBTENER_PROMOCIONES_FAIL'
export const SELECCIONAR_PROMOCION = 'SELECCIONAR_PROMOCION'
export const OBTENER_CANALES_DE_PROMOCIONES_FAIL = 'OBTENER_CANALES_DE_PROMOCIONES_FAIL'
export const ACTUALIZAR_CATEGORIAS_DE_PROMOCIONES = 'ACTUALIZAR_CATEGORIAS_DE_PROMOCIONES'
export const ACTUALIZAR_COLOR_SELECCIONADO_PROMOCION = 'ACTUALIZAR_COLOR_SELECCIONADO_PROMOCION'
export const OBTENER_CANALES_DE_PROMOCIONES_EXITO = 'OBTENER_CANALES_DE_PROMOCIONES_EXITO'
export const ACTUALIZAR_CANALES_DE_PROMOCIONES = 'ACTUALIZAR_CANALES_DE_PROMOCIONES'
export const CAMBIAR_DISENIO_PROMOCIONES = 'CAMBIAR_DISENIO_PROMOCIONES'
export const MOSTRAR_PROMOCIONES_NUEVAS = 'MOSTRAR_PROMOCIONES_NUEVAS'
export const OBTENER_DATA_RESUMEN_PROMOCIONES = 'OBTENER_DATA_RESUMEN_PROMOCIONES'

export const SELECCIONAR_CATEGORIA_PROMOCIONES = 'SELECCIONAR_CATEGORIA_PROMOCIONES'

// ENVIAR MAIL
export const ENVIAR_MAIL_PROMOCIONES_ACTIVAS = 'ENVIAR_MAIL_PROMOCIONES_ACTIVAS'
export const ENVIAR_MAIL_PROMOCIONES_NUEVAS = 'ENVIAR_MAIL_PROMOCIONES_NUEVAS'

