import {
    OBTENER_DATOS_CONTROL_ARCHIVOS,
    OBTENER_DATOS_AREAS,
    CARGANDO_TABLA_DATOS_STATUS,
    OBTENER_DETALLES_PROMOCIONES
} from '../../../Constantes/Status/Status'

const INIT_STATE = {
    cargandoTablaStatus: false,
    data_control_archivos : [],
    data_areas : [],
    data_detalle_promociones : [],
    cantidad_total : "",
    cantidad_norte : "",
    cantidad_sur   : "",
    cantidad_lima  : ""
};

export default (state = INIT_STATE, action) => {
    switch(action.type){
        case OBTENER_DATOS_CONTROL_ARCHIVOS: {
            return {
                ...state,
                data_control_archivos: action.payload.datos,
                cargandoTablaStatus: action.payload.cargandoSpin
            }
        }
        case OBTENER_DATOS_AREAS: {
            return {
                ...state,
                data_areas: action.payload
            }
        }
        case CARGANDO_TABLA_DATOS_STATUS: {
            return {
                ...state,
                cargandoTablaStatus : action.payload
            }
        }
        case OBTENER_DETALLES_PROMOCIONES: {
            return {
                ...state,
                data_detalle_promociones: action.payload.datos,
                cantidad_total : action.payload.cantidad_total,
                cantidad_norte : action.payload.cantidad_norte,
                cantidad_sur : action.payload.cantidad_sur,
                cantidad_lima : action.payload.cantidad_lima
            }
        }
        default:
            return state;
    }
}