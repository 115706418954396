import config from '../../../config'
import { estadoRequestReducer } from "../EstadoRequest"
import {
    OBTENER_DATOS_CONTROL_ARCHIVOS,
    OBTENER_DATOS_AREAS,
    CARGANDO_TABLA_DATOS_STATUS,
    OBTENER_DETALLES_PROMOCIONES
} from '../../../Constantes/Status/Status'

export const ObtenerDataControlArchivosReducer = (anio, mes) =>async (dispatch, getState) => {

    dispatch({
        type: CARGANDO_TABLA_DATOS_STATUS,
        payload: true
    })

    await fetch(config.api+'status/obtener',
        {
            mode:'cors',
            method: 'POST',
            body: JSON.stringify({
                'req_anio' : anio,
                'req_mes' : mes
            }),
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api_token': localStorage.getItem('usutoken'),
                'api-token': localStorage.getItem('usutoken'),
            }
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(async data => {
        const estadoRequest = getState().estadoRequest.init_request
        if(estadoRequest == true){
            dispatch({
                type: OBTENER_DATOS_CONTROL_ARCHIVOS,
                payload : {
                    datos: data.datos,
                    cargandoSpin : false,

                }
            })
        }
    }).catch((error)=> {
        console.log(error)
    });

}

export const ObtenerDataAreasReducer = (anio, mes) =>async (dispatch, getState) => {

    await fetch(config.api+'status/areas',
        {
            mode:'cors',
            method: 'POST',
            body: JSON.stringify({
                'req_anio' : anio,
                'req_mes'  : mes
            }),
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api_token': localStorage.getItem('usutoken'),
                'api-token': localStorage.getItem('usutoken'),
            }
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(async data => {
        const estadoRequest = getState().estadoRequest.init_request
        if(estadoRequest == true){
            dispatch({
                type: OBTENER_DATOS_AREAS,
                payload : data.datos
            })
        }
    }).catch((error)=> {
        console.log(error)
    });

}

export const ObtenerDataDetallesPromocionesReducer = (anio, mes, zona)  =>async (dispatch, getState) => {

    await fetch(config.api+'obtener-detalles-promocional',
        {
            mode:'cors',
            method: 'POST',
            body: JSON.stringify({
                'req_anio' : anio,
                'req_mes'  : mes,
                'req_zona' : zona
            }),
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api_token': localStorage.getItem('usutoken'),
                'api-token': localStorage.getItem('usutoken'),
            }
        }
    )
    .then( async res => {
        await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(async data => {
        const estadoRequest = getState().estadoRequest.init_request
        if(estadoRequest == true){
            console.log(data)
            dispatch({
                type: OBTENER_DETALLES_PROMOCIONES,
                payload : {
                    datos : data.datos,
                    cantidad_total : data.total_mecanicas,
                    cantidad_norte : data.cantidad_norte,
                    cantidad_sur   : data.cantidad_sur,
                    cantidad_lima  : data.cantidad_lima,
                }
            })         
        }
    }).catch((error)=> {
        console.log(error)
    });

}
