import axios from 'axios'
import {message} from "antd";
import config from '../../../config'
import {
    GUARDAR_NOTIFICACIONES_CARGA_ARCHIVOS
} from '../../../Constantes/CargaArchivos/CargaArchivos'
import {
    EnviarMailPromocionesActivasReducer,
    EnviarMailPromocionesNuevasReducer
} from '../../../Redux/Acciones/Promociones/MailPromociones'

export const CargarArchivoReducer = (url, data, tipo) => async(dispatch, getState) => {

    let nameFile = ""
    if(data.get('file')?.name){
        nameFile = data.get('file').name
    }

    let statusPromotions = {
        dataBase : []
    }

    let respuesta = false

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'multipart/form-data',
    }

    let updateStatusCorporate = [
        {
            url : "cargarArchivo/clientes",
            dataBase : ["Maestra de Clientes"]
        },
        {
            url : "cargarArchivo/productos",
            dataBase : ["Maestra de Productos"]
        },
        {
            url : "cargarArchivo/ventas/sellout",
            dataBase : ["Avance Sell Out"]
        },
        {
            url : "cargarArchivo/ventas/sellin",
            dataBase : ["Avance Sell In"]
        },
        {
            url : "cargarArchivo/ventas/rebate",
            dataBase : ["Escala Rebate Sell Out", "Escala Rebate Sell In"]
        },
        {
            url : "cargarArchivo/ventas/obejtivossellout",
            dataBase : ["Cuota Sell Out"]
        },
        {
            url : "cargarArchivo/ventas/obejtivos",
            dataBase : ["Cuota Sell In"]
        },
    ]

    updateStatusCorporate.map(sta => {
    
        if(tipo == sta.url){
            statusPromotions.dataBase = statusPromotions.dataBase.concat(sta.dataBase)
        }
    })

    if(config.produccion == true){
        headerFetch = {
            'Accept' : 'application/json',
            'content-type': 'multipart/form-data',
            'api_token': localStorage.getItem('usutoken'),
            'api-token': localStorage.getItem('usutoken'),
        }
    }

    await axios.post(url, data,{
        mode:'cors',
        headers: headerFetch
    })
    // .then(rpta => {
    .then(async rpta => {
        let datos = rpta.data
        if(datos.respuesta == true){
            respuesta = true
            const textoGlobal = new RegExp(config.api, "gi")
            const urlResult = url.replace(textoGlobal, "")
            if(urlResult == "cargarArchivo/ventas/sellin"){
                if(datos.fecfecid){
                    await dispatch(UpdatedRebateReducer(datos.fecfecid.fecid))
                    await dispatch(UpdatedToGoReducer(datos.fecfecid.fecid))
                }
            }
        }else{
            // message.error(datos.mensaje);
        }


        if(respuesta == false){
            let notificaciones_data_carga_archivos = getState().cargaArchivos.notificaciones_data_carga_archivos

            let nuevaNotificacion = datos.notificacionesLogs
            let numeroNotificacion = parseInt(notificaciones_data_carga_archivos.length)+1
            nuevaNotificacion.titulo = "Notificación "+numeroNotificacion

            notificaciones_data_carga_archivos.unshift(nuevaNotificacion)

            dispatch({
                type: GUARDAR_NOTIFICACIONES_CARGA_ARCHIVOS,
                payload: notificaciones_data_carga_archivos
            })

        }else{

            if(tipo == "Mecanica de Promociones"){

                datos.zonas.map(zon => {
                    if(zon.includes("lima")){
                        if(!statusPromotions.dataBase.includes("Promociones Lima")){
                            statusPromotions.dataBase.push("Promociones Lima")
                        }
                    }
                    if(datos.zonas.includes("norte")){
                        if(!statusPromotions.dataBase.includes("Promociones Norte")){
                            statusPromotions.dataBase.push("Promociones Norte")
                        }
                    }
                    if(datos.zonas.includes("sur")){
                        if(!statusPromotions.dataBase.includes("Promociones Sur")){
                            statusPromotions.dataBase.push("Promociones Sur")
                        }
                    }        
                })
            }
            
            if(statusPromotions.dataBase.length > 0){
                let rpsStatusCorporate = await dispatch(UpdateStatusCorporateReducer(statusPromotions, nameFile))
            }

            let notificaciones_data_carga_archivos = getState().cargaArchivos.notificaciones_data_carga_archivos

            let numeroNotificacion = parseInt(notificaciones_data_carga_archivos.length)+1

            let nuevaNotificacion = {
                "RESPUESTA" : true,
                "titulo" : "Notificación "+numeroNotificacion,
                "MENSAJE" : "El archivo se subio correctamente"
            }

            notificaciones_data_carga_archivos.unshift(nuevaNotificacion)

            dispatch({
                type: GUARDAR_NOTIFICACIONES_CARGA_ARCHIVOS,
                payload: notificaciones_data_carga_archivos
            })

            // if(tipo == "Mecanica de Promociones"){
            //     dispatch(EnviarMailPromocionesActivasReducer(
            //         datos.sucursalesSeleccionadas,
            //         datos.fechaSeleccionadaMes
            //     ))

            //     if(datos.sucursalesSeleccionadasPromocionesNuevas.length > 0){
            //         dispatch(EnviarMailPromocionesNuevasReducer(
            //             datos.sucursalesSeleccionadas,
            //             datos.fechaSeleccionadaMes
            //         ))
            //     }
            // }
        }

        // dispatch(ObtenerNotificacionesReducer(datos.notificacionesLogs))

    })
    .catch((error)=> {
        console.log(error)
    });

    return respuesta
}

export const EliminarNotificacionReducer = (pos) => (dispatch, getState) => {

    let notificaciones_data_carga_archivos = getState().cargaArchivos.notificaciones_data_carga_archivos
}

export const UpdateStatusCorporateReducer = (status, nameFile) => async (dispatch, getState) => {

    let response = false

    await fetch(config.apiCorporate+'public/status/update-database',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
            },
            body: JSON.stringify({
                req_usucorreo       : localStorage.getItem("usuusuario"),
                req_espbasedatos    : status.dataBase,
                req_plataforma      : "SMARTVIEW",
                req_nombrearchivo   : nameFile.toUpperCase()
            })
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            response = true
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const UpdatedRebateReducer = (fecid) => async () => {
    await fetch(config.api+'configuracion/rebate/actualizar/rebate',
        {
            mode:'cors',
            method: 'POST',
            body: JSON.stringify({
                fecha: fecid,
            }),
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api_token': localStorage.getItem('usutoken'),
                'api-token': localStorage.getItem('usutoken')
            }
        }
    )
    .then(res => res.json())
    .catch((error)=> {
        console.log(error)
    })
}

export const UpdatedToGoReducer = (fecid) => async () => {
    await fetch(config.api+'actualizarTogo/'+fecid,
        {
            mode:'cors',
            method: 'GET',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'api_token': localStorage.getItem('usutoken'),
                'api-token': localStorage.getItem('usutoken')
            }
        }
    )
    .then(res => res.json())
    .catch((error)=> {
        console.log(error)
    })
}