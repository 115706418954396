export const OBTENER_REBATES_ACTUALES_REBATE = 'OBTENER_REBATES_ACTUALES_REBATE'
export const CARGANDO_DATA_REBATE = 'CARGANDO_DATA_REBATE'
export const SELECCIONAR_GRUPO_REBATE = 'SELECCIONAR_GRUPO_REBATE'
export const OBTENER_GRUPOS_REBATES_REBATE = 'OBTENER_GRUPOS_REBATES_REBATE'
export const OBTENER_REBATE_DESCARGAR_REBATE = 'OBTENER_REBATE_DESCARGAR_REBATE'
export const CARGANDO_DATA_REBATE_BONUS = 'CARGANDO_DATA_REBATE_BONUS'
export const OBTENER_DATA_REBATE_BONUS = 'OBTENER_DATA_REBATE_BONUS'
export const CARGANDO_DATA_REBATE_TRIMESTRAL = 'CARGANDO_DATA_REBATE_TRIMESTRAL'
export const OBTENER_DATA_REBATE_TRIMESTRAL = 'OBTENER_DATA_REBATE_TRIMESTRAL'
export const CARGANDO_GUARDAR_REBATE_BONUS = 'CARGANDO_GUARDAR_REBATE_BONUS'
export const CARGANDO_GUARDAR_REBATE_MENSUAL = 'CARGANDO_GUARDAR_REBATE_MENSUAL'
export const CARGANDO_BTN_GUARDAR_TRIMESTRE = 'CARGANDO_BTN_GUARDAR_TRIMESTRE'