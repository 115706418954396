import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { Row, Col } from 'antd'

import '../../../Estilos/Componentes/Status/ModalStatus.css'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
    ObtenerDataDetallesPromocionesReducer
} from '../../../Redux/Acciones/Status/Status'

const ModalStatus = (props) => {

    const modalAbierto = props.modalAbierto
    const setAbrilModal = props.setAbrilModal

    const [btnSeleccionado, setBtnSeleccionado] = useState("TODOS")
    const dispatch = useDispatch()

    const {
        data_detalle_promociones,
        cantidad_total,
        cantidad_norte,
        cantidad_sur,
        cantidad_lima
    } = useSelector(({status}) => status);

    const {
        anioSeleccionadoFiltro,
        mesSeleccionadoFiltro
    } = useSelector(({fechas}) => fechas);

    const SeleccionarZona = (zona) => {
        setBtnSeleccionado(zona)
        dispatch(ObtenerDataDetallesPromocionesReducer(anioSeleccionadoFiltro, mesSeleccionadoFiltro, zona))
    }
    
    return (
        <div>
            <Modal
                centered
                title={null}
                visible={modalAbierto}
                footer={null}
                closeIcon={<CloseCircleOutlined style={{fontSize: '25px'}}/>}
                width="969px"
                height="363px"
                className='Caja-Modal-ReenviarElemento'
                onCancel={() => setAbrilModal(false)}
             >
                <div>
                    <Row>
                        <Col lg={24} xl={24}>
                            <div className='Contenedor-Modal-Datos'>
                                <div 
                                    style={{marginRight: '26px'}}
                                    onClick={() => SeleccionarZona("TODOS")}
                                    className = {btnSeleccionado == 'TODOS' 
                                        ? 'Btn-Seleccionado-Caja-Promociones' 
                                        : 'Btn-NoSeleccionado-Caja-Promociones'}
                                >
                                    <div className='Txt-Caja-Promociones'>Mecánicas Promocionales</div>
                                    <div className='Numero-Caja-Promociones'>{cantidad_total}</div>
                                </div>
                                <div 
                                    style={{marginRight: '26px'}}
                                    onClick={() => SeleccionarZona("LIMA")}
                                    className = {btnSeleccionado == 'LIMA' 
                                        ? 'Btn-Seleccionado-Caja-Lima' 
                                        : 'Btn-NoSeleccionado-Caja-Lima'}
                                >
                                    <div className='Txt-Caja-Lima'>Lima</div>
                                    <div className='Numero-Caja-Lima'>{cantidad_lima}</div>
                                </div>
                                <div 
                                    style={{marginRight: '26px'}}
                                    onClick={() => SeleccionarZona("NORTE")}
                                    className = {btnSeleccionado == 'NORTE' 
                                        ? 'Btn-Seleccionado-Caja-Norte' 
                                        : 'Btn-NoSeleccionado-Caja-Norte'}
                                >
                                    <div className='Txt-Caja-Lima'>Norte</div>
                                    <div className='Numero-Caja-Lima'>{cantidad_norte}</div>
                                </div>
                                <div 
                                    onClick={() => SeleccionarZona("SUR")}
                                    className = {btnSeleccionado == 'SUR' 
                                        ? 'Btn-Seleccionado-Caja-Sur' 
                                        : 'Btn-NoSeleccionado-Caja-Sur'}
                                >
                                    <div className='Txt-Caja-Lima'>Sur</div>
                                    <div className='Numero-Caja-Lima'>{cantidad_sur}</div>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '17px'}}>
                        <Col lg={24} xl={24}>
                            <div className='Contenedor-Modal-Tabla-Status'>
                                <table className='Modal-Tabla-Status'>
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Base de Datos</th>
                                            <th>Distribuidora</th>
                                            <th>Usuario</th>
                                            <th>DeadLine</th>
                                            <th>Fecha de Carga</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data_detalle_promociones.map((detalle, pos) => {
                                                return(
                                                    <tr>
                                                        <td>{pos + 1}</td>
                                                        <td>{detalle.badnombre}</td>
                                                        <td>{detalle.sucnombre}</td>
                                                        <td>{detalle.usuusuario}</td>
                                                        <td>{detalle.fecha_limite}</td>
                                                        <td>{detalle.fecha_creacion}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default ModalStatus