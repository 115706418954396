export default {
    api: 'https://backend-smartview.grow-corporate.com/',
    // api: 'https://subsidios.gavsistemas.com/',
    // api: 'https://pre-back.leadsmartview.com/',
    // api: 'http://127.0.0.1:8000/',
    apiCorporate : "https://gia-back.grow-corporate.com/",
    // apiCorporate : "http://127.0.0.1:8001/",
    basename: '/', 
    produccion : true,

    // api_microservices: 'http://localhost:8004/',
    api_microservices: 'https://express-smartview.grow-corporate.com/',

    nombreSistema : 'SmartView',
    nombreSistemaHo : 'SmartView',
    nombreCliente : 'Kimberly Clark Perú'
};
