export const OBTENER_GRUPOS_DISPONIBLES_LISTA_PRECIOS = "OBTENER_GRUPOS_DISPONIBLES_LISTA_PRECIOS"
export const OBTENER_DATA_EXCEL_LISTA_PRECIOS = "OBTENER_DATA_EXCEL_LISTA_PRECIOS"
export const CARGANDO_TABLA_LISTA_PRECIOS = "CARGANDO_TABLA_LISTA_PRECIOS"


export const SELECCIONAR_COLUMNA_FILTRO_DESCARGA_LISTA_PRECIOS = "SELECCIONAR_COLUMNA_FILTRO_DESCARGA_LISTA_PRECIOS"
export const ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_LISTA_PRECIOS = "ABRIR_AGRUPACION_COLUMNA_FILTRO_DESCARGAR_LISTA_PRECIOS"
export const OBTENER_DATA_DESCARGAR_EXCEL_LISTAPRECIOS_DESCARGAR = "OBTENER_DATA_DESCARGAR_EXCEL_LISTAPRECIOS_DESCARGAR"
export const CARGANDO_BTN_EXCEL_DESCARGAR_LISTA_PRECIOS = "CARGANDO_BTN_EXCEL_DESCARGAR_LISTA_PRECIOS"
export const OBTENER_DATA_FILTRO_LISTA_PRECIOS = "OBTENER_DATA_FILTRO_LISTA_PRECIOS"
export const OBTENER_UNICAMENTE_DATA_LISTA_PRECIOS = "OBTENER_UNICAMENTE_DATA_LISTA_PRECIOS"
export const SELECCIONAR_TODO_FILTROS_LISTA_PRECIOS = "SELECCIONAR_TODO_FILTROS_LISTA_PRECIOS"

export const OBTENER_DATA_CONFIGURACION_PAGINATE_DATA_LISTA_PRECIOS = "OBTENER_DATA_CONFIGURACION_PAGINATE_DATA_LISTA_PRECIOS"
export const CARGANDO_ELIMINAR_FILA_LISTA_PRECIOS = "CARGANDO_ELIMINAR_FILA_LISTA_PRECIOS"
export const CARGANDO_EDITAR_FILA_LISTA_PRECIOS = "CARGANDO_EDITAR_FILA_LISTA_PRECIOS"
export const ACTIVAR_DUPLICADOS_COMPLEJOS_LISTA_PRECIOS = "ACTIVAR_DUPLICADOS_COMPLEJOS_LISTA_PRECIOS"
export const FILTRO_CUSTOMER_GROUP_LISTA_PRECIOS = "FILTRO_CUSTOMER_GROUP_LISTA_PRECIOS"
export const SELECCIONAR_TODO_FILTRO_GRUPO_LISTA_PRECIOS = "SELECCIONAR_TODO_FILTRO_GRUPO_LISTA_PRECIOS"
export const ACTIVAR_DUPLICADOS_PRODUCTOS_LISTA_PRECIOS = "ACTIVAR_DUPLICADOS_PRODUCTOS_LISTA_PRECIOS"